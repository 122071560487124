import React from 'react'

import { cn, withBemMod } from '@hmm/utils'
import { cnPage, PageProps } from '../Page'
import styles from './Page_view_auth.module.css'
import { ThemeHmm } from '../../../themes'

export interface PageViewAuthProps {
  view?: 'auth'
}

export const withViewAuth = withBemMod<PageViewAuthProps, PageProps>(
  cn(cnPage, styles),
  {
    view: 'auth'
  },
  Page => props => {
    return (
      <ThemeHmm>
        <Page {...props} className={cnPage({ view: 'auth' })}>
          {props.children}
        </Page>
      </ThemeHmm>
    )
  }
)
