const CUSTOMER = {
  profile: '/my/orders/',
  orders: '/my/orders/',
  settings: '/my/account'
}

const AUTH = {
  login: '/login',
  logout: '/logout',
  registration: '/registration',
  reset: '/password/reset'
}

const MANAGE = {
  index: '/manage'
}

export const ROUTES = {
  home: '/',
  checkout: '/checkout',
  wishlists: '/wishlists',
  categories: '/categories',
  cart: '/cart',
  auth: AUTH,
  customer: CUSTOMER,
  manage: MANAGE
}
