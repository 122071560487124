import React from 'react'
import { IClassNameProps, cn } from '@hmm/utils'
import styles from './Message.module.css'

export const cnMessage = cn('Message', styles)
export interface IMessageProps extends IClassNameProps {
  size?: 's' | 'm' | 'l'
  status?: 'success' | 'error' | 'warning',
  children?: React.ReactNode
}

export const Message: React.FC<IMessageProps> = ({
  size,
  status,
  children,
  ...props
}) => (
  <div className={cnMessage({ size, status }, [props.className])}>
    {children}
  </div>
)
