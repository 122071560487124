import { unstable_useId as reactId } from "@mui/material";

export const useUniqId = function(customId?: string): string {
  return reactId(customId) || '';
  /*
  const generatedId = reactId()
  return customId || generatedId
   */
}

export const useId = useUniqId
