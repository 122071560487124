import React from 'react'
import clsx from 'clsx'
import { useId } from '@hmm/hooks'
import {
  styled,
  useThemeProps,
  FormControl,
  FormHelperText,
  OutlinedTextFieldProps,
  Select
} from '@mui/material'
import { OutlinedInput } from '@hmm/components/OutlinedInput'
import styles from './TextField.module.css'

const Message = styled(FormHelperText)(({ theme, color = 'primary' }) => ({
  margin: 0,
  ['&.Mui-focused']: {
    // @ts-ignore
    backgroundColor: theme.palette[color].main,
    // @ts-ignore
    color: theme.palette[color].contrastText,
    ['&.Mui-error']: {
      backgroundColor: theme.palette.error.main,
      color: theme.palette.error.contrastText
    },
    ['&.Mui-success']: {
      backgroundColor: theme.palette.success.main,
      color: theme.palette.success.contrastText
    }
  }
}))

export type TextFieldProps = Omit<OutlinedTextFieldProps, 'variant'> & {
  background?: 'filled'
}

export const TextField = (inProps: TextFieldProps) => {
  const { helperText, label, FormHelperTextProps, InputLabelProps, ...props } =
    useThemeProps({ props: inProps, name: 'MuiTextField' })
  const inputId = useId(props.id)
  const {
    autoComplete,
    autoFocus = false,
    children,
    className,
    color = 'primary',
    defaultValue,
    disabled = false,
    error = false,
    fullWidth = false,
    id: idOverride,
    inputProps,
    InputProps,
    inputRef,
    maxRows,
    minRows,
    multiline = false,
    name,
    onBlur,
    onChange,
    onFocus,
    placeholder,
    required = false,
    rows,
    select = false,
    SelectProps,
    type,
    value,
    focused,
    background,
    ...other
  } = props

  const InputElement = (
    <OutlinedInput
      id={inputId}
      autoComplete={autoComplete}
      autoFocus={autoFocus}
      defaultValue={defaultValue}
      fullWidth={fullWidth}
      multiline={multiline}
      name={name}
      rows={rows}
      maxRows={maxRows}
      minRows={minRows}
      type={type}
      value={value}
      onBlur={onBlur}
      onFocus={onFocus}
      onChange={onChange}
      placeholder={placeholder}
      color={color}
      background={background}
      {...InputProps}
    />
  )

  return (
    <FormControl
      className={clsx(styles.TextField, {
        [styles.TextField_with_helperText]: Boolean(helperText)
      })}
      disabled={disabled}
      error={error}
      fullWidth={fullWidth}
      required={required}
      color={color}
      focused={focused}
      {...other}
    >
      {label && (
        <label {...InputLabelProps} htmlFor={inputId}>
          {label}
        </label>
      )}
      {select ? (
        <Select
          id={inputId}
          value={value}
          input={InputElement}
          {...SelectProps}
        >
          {children}
        </Select>
      ) : (
        InputElement
      )}
      {helperText && (
        <Message
          {...FormHelperTextProps}
          color={color}
          disabled={disabled}
          error={error}
          focused={focused}
          className={styles.helperText}
        >
          {helperText}
        </Message>
      )}
    </FormControl>
  )
}
