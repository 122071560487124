import MuiOutlinedInput, {
  OutlinedInputProps as MuiOutlinedInputProps
} from '@mui/material/OutlinedInput'
import { styled } from '@mui/material'

export interface OutlinedInputProps extends MuiOutlinedInputProps {
  background?: 'filled'
}

export const OutlinedInput = styled(
  ({ background: _, ...props }: OutlinedInputProps) => (
    <MuiOutlinedInput {...props} />
  )
)(({ theme, background }) => ({
  backgroundColor:
    background === 'filled' ? theme.palette.background.paper : 'transparent'
}))
