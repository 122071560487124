export enum Texts {
  registration = 'Регистрация',
  isNew = 'Нет аккаунта?',
  notNew = 'Есть аккаунт?',
  login = 'Войти',
  loginTitle = 'Войти в профиль',
  remember = 'Запомнить меня',
  password = 'Пароль',
  passwordPlaceholder = 'Введите пароль',
  showPassword = 'Показать пароль',
  loginPlaceholder = 'Электронная почта',
  loginLabel = 'Электронная почта',
  goBack = 'Назад',
  register = 'Создать профиль',
  subscribe = 'Получать на почту информацию о новинках, акциях и скидках',
  nicknamePlaceholder = 'Ваше имя',
  nickname = 'Имя',
  reset = 'Восстановить пароль',
  doReset = 'Восстановить доступ',
  goLogin = 'Перейти к вводу пароля'
}

export default Texts
