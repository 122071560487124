import React, { FormHTMLAttributes } from 'react'
import { cnAuthForm } from './cn'
import { Logo } from '@hmm/components/Logo'
import { Button } from '@hmm/components/Button'
import { Texts as t } from './texts'
export { cnAuthForm } from './cn'

export interface IAuthFormProps extends FormHTMLAttributes<any> {
  csrf: string
  message?: string
  query?: object
  hasBack?: boolean
  onBackClick?: (e: React.BaseSyntheticEvent) => void
}

export interface IAuthFields {
  _token: string
}

export const AuthForm: React.FC<IAuthFormProps> = (props) => (
  <form
    action={props.action}
    method="POST"
    onSubmit={props.onSubmit}
    className={cnAuthForm({}, [props.className])}
  >
    <Button
      disabled={!props.hasBack}
      className={cnAuthForm('Back', { visible: props.hasBack })}
      onClick={props.onBackClick}
    >
      {t.goBack}
    </Button>
    <div className={cnAuthForm('Logo')}>
      <Logo view="square" link />
    </div>
    <input type="hidden" name="_token" value={props.csrf} />
    {props.children}
  </form>
)
