import React from 'react'
import { Button } from '@hmm/components/Button'
import { withRouter, useRoutes } from 'modules/Router'
import { cnAuthForm } from '../index'
import { Texts } from '../texts'

const RoutedButton = withRouter(Button)

export const AuthFormAlternateAction = ({
  action
}: {
  action: 'login' | 'registration'
}) => {
  const isLogin = action === 'login',
    routes = useRoutes(),
    intro = isLogin ? Texts.notNew : Texts.isNew,
    href = isLogin ? routes.auth.login: routes.auth.registration,
    btnText = isLogin ? Texts.login : Texts.registration

  return (
    <div className={cnAuthForm('AlternateAction')}>
      <p className={cnAuthForm('isNew')}>{intro}</p>
      <RoutedButton
        fullWidth
        href={href}
        className={cnAuthForm('ToRegistration')}
      >
        {btnText}
      </RoutedButton>
    </div>
  )
}
