import { useState } from 'react'
import { param } from '../../../utils/url/param'
import { FormikConfig, FormikErrors, FormikValues, useFormik } from 'formik'

export const useSendForm = function<T extends FormikValues>(
  url: string,
  formikConfig: Omit<FormikConfig<T>, 'onSubmit'>
) {
  const [isSending, setSending] = useState(false)
  const [message, setMessage] = useState('')

  const onSubmit = (values: object) => {
    setSending(true)
    send(formatFields(values), url)
      .then(res => {
        formik.setStatus('success')
        const message = res.message
        message && setMessage(message)
        if (res.redirect_url) {
          window.location = res.redirect_url
        } else {
          setSending(false)
        }

        return res
      })
      .catch(e => {
        formik.setStatus('error')
        if (e instanceof Response) {
          return e.json().then(res => {
            setSending(false)
            if (res.message) {
              setMessage(res.message)
            } else if (res.data && res.data.message) {
              // Появляется если проблема на бекенде
              setMessage(res.data.message)
            }
            if (res.data && res.data.errors) {
              const errs = preprocessBackendErrors<T>(res.data.errors)
              formik.setErrors(errs)
            }
          })
        }
        throw e
      })
  }

  const formik = useFormik<T>(Object.assign({ onSubmit }, formikConfig))

  return {
    isSending,
    message,
    formik
  }
}

function send(values: object, url: string) {
  return fetch(url, {
    method: 'post',
    headers: {
      'Content-type': 'application/x-www-form-urlencoded; charset=UTF-8'
    },
    credentials: 'include',
    body: param(values)
  })
    .then(res => (res.ok ? Promise.resolve(res) : Promise.reject(res)))
    .then(res => res.json())
}

/**
 * Преобразует значения полей формы в формат бекенда
 * @param values
 */
function formatFields(values: object): FormikValues {
  const res: FormikValues = {}
  Object.entries(values).forEach(([name, val]) => {
    if (typeof val === 'boolean') {
      res[name] = val ? 1 : 0 // backend ждет такой формат
    } else {
      res[name] = val
    }
  })

  return res
}

interface IBackendErrors {
  [key: string]: Array<string>
}

/**
 * Преобразует формат ошибок бекенда в формат formik
 * @param errors
 */
function preprocessBackendErrors<Values>(
  errors: IBackendErrors
): FormikErrors<Values> {
  const result: FormikErrors<Values> = {}
  for (let fieldName in errors) {
    if (!errors.hasOwnProperty(fieldName)) {
      continue
    }
    if (Array.isArray(errors[fieldName])) {
      // @ts-ignore
      result[fieldName] = errors[fieldName][0]
    } else {
      throw new Error('Unexpected backend error format')
    }
  }

  return result
}
