import React, {
  useCallback,
  MouseEventHandler,
  ComponentType,
  ReactNode,
  ReactElement
} from 'react'
import { useRouter } from './useRouter'
import { UrlObject } from 'url'

interface ComponentProps {
  href?: string | UrlObject
  onClick?: MouseEventHandler<HTMLButtonElement | HTMLAnchorElement>
  children?: ReactNode
}

interface WithRouterProps {
  options?: { shallow?: boolean }
}

export function withRouter<T extends ComponentProps = ComponentProps>(
  Component: ComponentType<T>
): ComponentType<T & WithRouterProps> {
  return function ({
    options,
    onClick,
    ...props
  }: any & WithRouterProps): ReactElement<T> {
    const router = useRouter()
    const onClick_modified: MouseEventHandler<
      HTMLButtonElement | HTMLAnchorElement
    > = useCallback(
      (e) => {
        onClick && onClick(e)
        if (e.defaultPrevented || !router) return
        e.preventDefault()
        props.href && router.push(props.href, undefined, options)
      },
      [router, props.href, onClick, options]
    )

    return React.createElement(
      Component,
      Object.assign({ onClick: onClick_modified }, props)
    )
  }
}
